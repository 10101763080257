import React, {useState} from "react";
import Layout from "../components/Layout";
import {Link} from "gatsby";

//components
import Accordion from "../components/Accordion";

const Service_one = () => {
    const [one, setOne] = useState(false);
    const [two, setTwo] = useState(false);
    const [three, setThree] = useState(false);
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div className="services_banner"
                         style={{backgroundImage: `url(https://images.pexels.com/photos/3861958/pexels-photo-3861958.jpeg?cs=srgb&dl=pexels-thisisengineering-3861958.jpg&fm=jpg)`}}>
                        <h1>PHP Web Development</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <h1 style={{color: '#000000', fontSize: 20}}>Why Your Business May Need A PHP Based Web
                                Solution</h1>
                            <div>
                                <p className="services_section_1__paragraph">
                                    For the newbies among us, PHP is a web development language that has been widely
                                    adopted for multiple websites throughout the world.
                                    It also has its uses in general programming functions. Literally millions of
                                    websites are now running on the PHP language which means that your e-commerce
                                    business may need to be founded on the PHP language as well to facilitate smooth
                                    operations with other PHP based websites.
                                    If you are looking for a developer to make a PHP based web solution for you, Tyrion
                                    Tech could be the right company for the job.
                                </p>
                            </div>
                            <div>
                                <table className="gh-pricing-table">
                                    <tbody>
                                    <tr className="gh-pricing-table-noborder">
                                    </tr>
                                    <tr className="gh-pricing-category" onClick={() => setOne(!one)}
                                        style={{cursor: 'pointer'}}>
                                        <td colSpan="11">Benefits</td>
                                        <td style={{border: 0}}>
                                            <img src="https://img.icons8.com/material-sharp/24/000000/sort-down.png"
                                                 style={{width: 15, height: 10}}/>
                                        </td>
                                    </tr>
                                    <tr style={{display: (one ? "" : "none")}}>
                                        <td colSpan={12} style={{backgroundColor: "white"}}>
                                            <p style={{fontSize: "medium"}}>
                                                One benefit of switching to PHP is that it is free software based on the
                                                PHP License. Most web servers can accommodate a site built on PHP so
                                                that eases one problem from your list of worries. Most operating systems
                                                or platforms will also accept the PHP as a standalone shell as well. So
                                                not only do you get it free of charge but you can use PHP without
                                                worrying much that it cannot be compatible with servers, operating
                                                systems or platforms. By assuring compatibility, it means your
                                                e-commerce site can function smoothly most of the time. If you do
                                                encounter any problems that need a technical solution, turn to Tyrion
                                                Tech to provide that assistance.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr className="gh-pricing-category" onClick={() => setTwo(!two)}
                                        style={{cursor: 'pointer'}}>
                                        <td colSpan="11">Advantage</td>
                                        <td style={{border: 0}}>
                                            <img src="https://img.icons8.com/material-sharp/24/000000/sort-down.png"
                                                 style={{width: 15, height: 10}}/>
                                        </td>
                                    </tr>
                                    <tr style={{display: (two ? "" : "none")}}>
                                        <td colSpan={12} style={{backgroundColor: "white"}}>
                                            <p style={{fontSize: "medium"}}>
                                                Because PHP is an open-source code, you can actually have the source
                                                code for your site amended if necessary. You can do this code tweaking
                                                yourself or you can hire a more experienced developer for the job. Open
                                                source means any changes can be done without having to pay the owners of
                                                the PHP source code which is the PHP Group developer. You probably will
                                                easily be able to find a web hosting provider that can support your
                                                PHP-based website.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr className="gh-pricing-category" onClick={() => setThree(!three)}
                                        style={{cursor: 'pointer'}}>
                                        <td colSpan="11">Why change</td>
                                        <td style={{border: 0}}>
                                            <img src="https://img.icons8.com/material-sharp/24/000000/sort-down.png"
                                                 style={{width: 15, height: 10}}/>
                                        </td>
                                    </tr>
                                    <tr style={{display: (three ? "" : "none")}}>
                                        <td colSpan={12} style={{backgroundColor: "white"}}>
                                            <p style={{fontSize: "medium"}}>
                                                switching to PHP code can accommodate web content management systems
                                                founded on PHP. Your e-commerce system might be using Joomla! for
                                                example, so you will be pleased to know that Joomla! is compatible with
                                                your PHP-based site. Another commonly used web content management system
                                                is Moodle which also happens to work well with a PHP-based website. Many
                                                e-commerce businesses do need some kind of web content management system
                                                so it is best to choose one that will work smoothly on your PHP coded
                                                website for the long term. Otherwise, you may need help from a developer
                                                to figure out the technical issues that are in the way.
                                            </p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Service_one
